import React from 'react'

const about = () => {
  return (
    <div>
        <center>
            <br></br>
        <header>
            <h1>Hi!</h1>
        </header>
        <main>
            <p>Welcome to my website! My name is Angel and I'm a 18-year-old aspiring software developer.</p>
            <p>This website is currently under construction. Feel free to reach me at angelchen1929@gmail.com for any inquiries.</p>
        </main>
        </center>
    </div>
  )
}

export default about;